<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDAC53"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDAC53" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pt-4>
      <v-flex>
        <v-tabs
              fixed-tabs
              v-model="tabValue"
              color="#FDAC53"
              centered
              slider-color="yellow"
            >
              <v-tab v-for="item in titleArray" :key="item.value">
                {{ item.title }}
              </v-tab>
            </v-tabs>
      </v-flex>
    </v-layout>
    <!-- <v-tabs-items > -->
      <!-- <template v-if="tabValue==0"> -->
        <v-layout wrap v-if="tabValue==0">
          <v-flex xs12>
          <Programs/>

          </v-flex>
        </v-layout>
        <v-layout wrap v-if="tabValue==1">
          <v-flex xs12>
          <Programs2 />

          </v-flex>
        </v-layout>
        <!-- <v-tab-item :key="item.value" :value="index">
        </v-tab-item> -->
        <!-- </template> -->
        <!-- <template v-if="tabValue==1"> -->
        <!-- <v-tab-item :key="item.value" :value="index">
        </v-tab-item> -->
      <!-- </template> -->
    <!-- </v-tabs-items> -->
  </div>
</template>
<script>
import axios from "axios";
import Programs from './../GetInvolved/getInvolvedStatic'
import Programs2 from './../GetInvolved/getInvolved'
export default {
  components: {
    Programs,
    Programs2,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      categories: [],
      programs: [],
      tabValue: 0,
      // titleArray: [
      //   { title: "Learn about the Reserve", value: "Learn about the Reserve" },
      //   { title: "Policies", value: "Policies" },
      //   { title: "Get Involved", value: "Terms and Conditions" },
      //   { title: "Page Contributor", value: "Page Contributors" },
      //   // { title: "COTTAGES", value: "cottage" },
      // ],
      titleArray: [
        { title: "Get Involved", value: "Get Involved" },
        { title: "Get Involved List", value: "Get Involved List" },
        // { title: "Page Contributor", value: "Page Contributors" },
        // { title: "COTTAGES", value: "cottage" },
      ],
    };
  },
  beforeMount() {
    this.getPrograms("started");
    // this.getCategories();
  },
  methods: {
    // winStepper(window_data) {
    //   if (window_data.response) this.slotAddition = window_data.slotAddition;
    //   this.packageRate = window_data.response;
    // },
    winStepper(windowData) {
      if (windowData.response.status) {
        this.msg = "Delete Sucessfully";
        this.showSnackbar = true;
        this.getPrograms("started");
        //   this.data=windowData.name
        // console.log(windowData);
      }
      //  else this.date = windowData.date;
    },
    getPrograms(val, catid) {
      console.log(this.tabValue)
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/programme/getlist",
        params: { programmestatus: val, category: catid, isCottage: false },
      })
        .then((response) => {
          this.appLoading = false;
          this.programs = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/programme/remove",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>