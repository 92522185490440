<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#68D389"
      size="128"
      is-full-screen
    />
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-if="!ServerError" wrap justify-center>
      <v-flex px-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-layout wrap justify-start v-if="volunteering">
          <template v-for="(item, i) in volunteering">
            <v-flex pa-6 xs12 xl11 :key="item._id" pr-lg-6 pr-xl-0>
              <v-layout wrap>
                <v-flex xs12 sm12 md12 lg6>
                  <v-layout wrap justify-start>
                    <v-flex xs12 sm5 md5 lg5 xl5>
                      <v-layout wrap>
                        <v-flex text-left xs12>
                          <span class="getText"> Name </span>
                        </v-flex>
                        <v-flex text-left xs12>
                          <span style="font-size: 16px">
                            {{ item.name }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm7 md7 lg7 xl7>
                      <v-layout wrap>
                        <v-flex text-left xs12>
                          <span class="getText"> Email </span>
                        </v-flex>
                        <v-flex text-left xs12>
                          <span class="getValue">
                            {{ item.email }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex text-left xs12 md12 lg6>
                  <v-layout wrap justify-space-between>
                    <v-flex xs12 sm6 md4 lg4 xl4>
                      <v-layout wrap>
                        <v-flex text-left xs12>
                          <span class="getText"> Contact Number </span>
                        </v-flex>
                        <v-flex text-left xs12>
                          <span class="getValue">
                            {{ item.contactNumber }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm6 md8 lg8 xl8>
                      <v-layout wrap fill-height>
                        <v-flex xs12 sm12>
                          <span class="getText"> Address </span>
                        </v-flex>
                        <v-flex xs12 sm12 text-left align-self-start>
                          <span class="getValue">
                            {{ item.address }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap pt-2>
                <v-flex sm12 md12 lg12 xl12>
                  <v-layout wrap fill-height>
                    <v-flex xs12 sm12 text-left>
                      <span class="getText"> Contribution </span>
                    </v-flex>
                    <v-flex xs12 sm12 text-left align-self-start>
                      <span class="getValue">
                        {{ item.contribution }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 xl11 :key="i">
              <v-divider></v-divider>
            </v-flex>
          </template>
        </v-layout>
        <div class="pt-2">
          <v-pagination
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#68d389"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["volunteering", "corporate", "itemHeading", "pages"],
  data() {
    return {
      appLoading: false,
      search: "",
      currentPage: 1,
      count: 20,
      totalData: 0,
      deliverystatus: null,
      totalRows: 0,
      ServerError: false,
    };
  },
  watch: {
    currentPage() {
      this.$emit("stepper", {
        currentPage: this.currentPage,
        count: 10,
      });
    },
  },
};
</script>